import React from 'react';

const About = () => {
  return (
    <p className="font-bold text-center sm:text-left text-accent text-4xl sm:text-6xl">
      About
    </p>
  );
}

export default About;