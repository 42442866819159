import React from 'react';
import leafImage from '../../images/leaf.svg'

const Banner = () => {
  return (
    <div className="
      flex flex-col justify-center items-center sm:items-start
      space-y-8 px-8 sm:px-32
      w-full sm:max-w-screen-lg h-[90vh]
      text-xl text-accent-gray text-center sm:text-left"
    >
      <div className="self-start w-12 border-b-2 rounded-lg border-accent"/>
      <p className="font-bold text-center sm:text-left text-accent text-4xl sm:text-6xl">
        Building a better tomorrow.
      </p>
      <p className="text-accent text-center sm:text-left">
        Contribute, learn and build with us on Discord!
      </p>
      <div className="self-end w-24 border-b-2 rounded-lg border-accent"/>
      <p className="text-left">
        AcademyDAO is a DAO for the people built by the people. A place where we can all contribute, learn and build a better tomorrow.
      </p>
      <p>
        Everyone is welcome.
      </p>
      <a className="
        transition ease-in-out duration-300 hover:scale-110
        text-2xl text-white bg-accent whitespace-nowrap
        inline-flex items-center justify-center
        px-20 py-4 border-0 rounded-lg shadow-md
        font-medium"
        href="https://discord.gg/9JMQyezjU3">
        JOIN US
      </a>
      <img className="absolute bottom-0 right-0 xl:block hidden" src={leafImage} alt=""/>
    </div>
  );
}

export default Banner;