import React from 'react';

const Mint = () => {
  return(
    <p className="font-bold text-center sm:text-left text-accent text-4xl sm:text-6xl">
      Mint
    </p>
  )
}

export default Mint;