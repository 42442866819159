import React from 'react';

const Learn = () => {
  return(
    <p className="font-bold text-center sm:text-left text-accent text-4xl sm:text-6xl">
      Learn
    </p>
  )
}

export default Learn;