import React from 'react'
import { Link } from 'react-router-dom'


const Dropdown = ({isOpen,toggle}) => {
  
    const routeButtonStyle = "mx-3 my-2 sm:my-0 ";
    
    return (
     
      <div className={` left-0 right-0 top-20 z-40 w-full bg-white grid text-center items-center fixed transition ease-in-out duration-300 ${
        isOpen ? "translate-y-0" : "translate-x-full"
      }`} onClick={toggle}>

        <Link to="about"><button className={routeButtonStyle}>About</button></Link>
        <Link to="learn"><button   className={routeButtonStyle}>Learn</button></Link>
        <Link to="mint"><button  className={routeButtonStyle}>Mint</button></Link>
        <Link to="launch"><button   className={routeButtonStyle}>Launch</button></Link>
        
      </div>
    )
}

export default Dropdown
