import React, { useState, useRef, useEffect } from 'react';

import NavBar from './Nav';
import WalletModal from './WalletModal';
import { useConnect, useAccount } from 'wagmi';
import { Outlet } from 'react-router-dom';
import Dropdown from './Dropdown';

const Layout = ({children}) => {

  const[isOpen,setIsOpen]= useState(false);
  const toggle =() =>{
      setIsOpen(!isOpen);
  };
  useEffect(()=>{
    const hideMenu=()=>{
      if(window.innerWidth>768 && isOpen){
        setIsOpen(false);
        console.log('i resized')
      };
    };
    window.addEventListener('resize',hideMenu)
    return()=>{
      window.removeEventListener('resize',hideMenu)
    };
  });


  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  const [{ data, error }, connect] = useConnect()
  const [{ data: accountData }, disconnect] = useAccount({
    fetchEns: true,
  });




  return(
    <div className="h-full w-full bg-main bg-cover ">
      <WalletModal open={open} cancelButtonRef={cancelButtonRef} setOpen={setOpen} data={data} error={error} connect={connect}/>
      <NavBar toggle={toggle} isOpen={isOpen} accountData={accountData} disconnect={disconnect} setOpen={setOpen}/>
    <Dropdown isOpen={isOpen} toggle={toggle} />
      <Outlet />
    </div>
  )
}

export default Layout;