import React from 'react';
import { Link } from "react-router-dom";
import logo16 from '../images/logo.svg';

const NavBar = (props) => {
  const { accountData, disconnect, setOpen,toggle,isOpen } = props;
  const buttonStyle = "transition ease-in-out duration-300 hover:scale-110 text-accent hover:text-accent bg-white whitespace-nowrap inline-flex items-center justify-center px-4 py-2 ml-3 border-0 rounded-full shadow-md font-medium";
  const routeButtonStyle = "mx-3 my-2 sm:my-0"

  return (
    <div className="flex flex-row justify-between items-center w-full h-24 sm:px-20 px-10 py-6 relative ">
      <Link to="/"><img className="hidden lg:block" src={logo16} alt=""/></Link>
      <div className='cursor-pointer lg:hidden absolute px-0'onClick={isOpen}>
      <Link to="/" ><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
</svg></Link>
</div>
      <div className='cursor-pointer relative ml-7 md:hidden 'onClick={toggle} >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
</svg>
</div>
<div className=' cursor-pointer md:hidden relative  'onClick={() => setOpen(true)}>
 <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
</svg>
</div>

      <div className="pr-8 md:block hidden">
        <Link to="about"><button className={routeButtonStyle}>About</button></Link>
        <Link to="learn"><button className={routeButtonStyle}>Learn</button></Link>
        <Link to="mint"><button className={routeButtonStyle}>Mint</button></Link>
        <Link to="launch"><button className={routeButtonStyle}>Launch</button></Link>
        {accountData
        ?
          <button onClick={disconnect} className={buttonStyle}>
            Disconnect {accountData.address.slice(0,4)}...
          </button>
        :
          <button onClick={() => setOpen(true)} className={buttonStyle}>
            Connect
          </button>
        }
      </div>
    </div>
  );
}

export default NavBar;