import React from 'react';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Learn from './pages/Learn';
import Mint from './pages/Mint';
import Launch from './pages/Launch';
import Layout from './components/Layout';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  InjectedConnector,
  Provider,
  WalletConnectConnector,
  WalletLinkConnector,
  chain,
  defaultChains,
} from 'wagmi'

function App() {
  const infuraId = "https://eth-mainnet.alchemyapi.io/v2/ecaT_TfjLePnUovRaZAHTMlBg1dhQNRT"
  const chains = defaultChains
  const connectors = ({ chainId }) => {
    const rpcUrl =
      chains.find((x) => x.id === chainId)?.rpcUrls?.[0] ??
      chain.mainnet.rpcUrls[0]
    return [
      new InjectedConnector({ chains }),
      new WalletConnectConnector({
        options: {
          infuraId,
          qrcode: true,
        },
      }),
      new WalletLinkConnector({
        options: {
          appName: 'Academy DAO',
          jsonRpcUrl: `${rpcUrl}/${infuraId}`,
        },
      }),
    ]
  }

  return (
    <Provider autoConnect connectors={connectors}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route path="/" element={<Home/>}/>
            <Route path="about" element={<About/>}/>
            <Route path="learn" element={<Learn/>}/>
            <Route path="mint" element={<Mint/>}/>
            <Route path="launch" element={<Launch/>}/>
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
